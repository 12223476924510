$font-color-danger: #FF355f;

.reits{
  &-react-select{
    &--invalid{
      //color:$font-color-danger;
    }

    &--feedback{
      color:$font-color-danger;
      display: inline;
    }

    &--no-feedback{
      display: none;
    }
  }
}
