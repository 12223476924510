// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/all.min.css';
// Import Flag Icons Set
//@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Main styles for this application
@import './scss/style.scss';
// Import Modules
// Company Mapping
@import 'views/CompanyMappingModule/CompanyMappingModule';
// Reits
@import 'views/ReitsModule/style';
