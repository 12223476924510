// Overrides CoreUI & Bootstrap Variables

//
// Color system
//

$white: #fff;
$gray-100: #f9f9fa;
$gray-200: #e1e6ef;
$gray-300: #c0cadd;
$gray-400: #869fac;
$gray-500: #678898;
$gray-600: #9faecb;
$gray-700: #3e515b;
$gray-800: #384042;
$gray-900: #151b1e;
$black: #000;

$blue: #20a8d8;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #ff5454;
$orange: #fabb3d;
$yellow: #ffc107;
$green: #79c447;
$teal: #20c997;
$cyan: #67c2ef;

// Import default variables
@import "node_modules/@coreui/coreui-pro/scss/variables";

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions: true;
$enable-rounded: false;

// Body
//
// Settings for the `<body>` element.

$body-bg: #f2f4f8;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base: 0.875rem;

// Breadcrumbs

$breadcrumb-padding-x: 2rem;
$breadcrumb-bg: transparent;
$breadcrumb-margin-bottom: 0;

// Cards

$card-border-color: $gray-200;
$card-cap-bg: $gray-100;

// Dropdowns

$dropdown-padding-y: 0;
$dropdown-border-color: $gray-200;
$dropdown-divider-bg: $gray-100;

// Buttons

$btn-secondary-border: $gray-300;

// Progress bars

$progress-bg: $gray-100;

// Tables

$table-bg-accent: $gray-100;
$table-bg-hover: $gray-100;

// Forms

$input-group-addon-bg: $gray-100;
$input-border-color: $gray-200;
$input-group-addon-border-color: $gray-200;

// coreUI overrides

$border-color: $gray-200;

// Navbar

$navbar-height: 60px;

$navbar-brand-width: 250px;
$navbar-brand-bg: $gray-200;
$navbar-brand-logo-size: 90px auto;
$navbar-brand-border: (
        bottom: (
                size: 1px,
                style: solid,
                color: darken(theme-color("primary"), 10%)
        )
);

$navbar-brand-minimized-bg: $navbar-brand-bg;
$navbar-brand-minimized-border: $navbar-brand-border;

$navbar-color: $gray-300;
$navbar-hover-color: darken($navbar-color, 10%);
$navbar-active-color: darken($navbar-color, 20%);
$navbar-disabled-color: lighter($navbar-color, 20%);

// Sidebar

$sidebar-width: 250px;
$sidebar-bg: $gray-800;
$mobile-sidebar-width: 250px;

// Sidebar Navigation

$sidebar-nav-title-padding-y: .95rem;
$sidebar-nav-title-color: $text-muted;

$sidebar-nav-link-hover-bg: darken($sidebar-bg, 5%);

$sidebar-nav-link-active-bg: darken($sidebar-bg, 5%);

$sidebar-nav-dropdown-bg: darken($sidebar-bg, 3%);

// Breadcrumb

$breadcrumb-margin: 0;
$breadcrumb-borders: 0;
