// Custom variables
@import "variables";
// Import styles with default layout.
@import "node_modules/@coreui/coreui-pro/scss/coreui";
// If you want to add something do it here
@import "custom";

html body {
  height: 100vh;
  min-height: 100%;
}
.full-screen-modal {
  .modal {
    padding: 0 !important; // override inline padding-right added from js
    max-width: none;

  }

  .modal .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal .modal-body {
    overflow-y: auto;
    max-width: 3000px;
    width: 100%;
    margin: 0;
    padding: 0;
  }
}
