$background-color-primary: #fecc00;
$background-color-secondary: #04355f;
$font-color-primary: #04355f;
$font-color-secondary: #F7F7F9;
$default-border-width: .1rem;
$default-border-radius: .25rem;
$default-margin: 0.25rem;

:export {
  bgColor: $background-color-primary;
  bgColorBlue: $background-color-secondary;
  fontColorPrimary: $font-color-primary;
  fontColorSecondary: $font-color-secondary;
  defaultBorderRadius: $default-border-radius;
}
