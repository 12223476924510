@import "variables";

.switch-secondary .switch-input:checked + .switch-slider {
  background-color: $font-color-primary;
  border-color: #9faecb;
}

.company-mapping {
  .card {
    &-header {
      background-color: $background-color-primary;
      color: $font-color-primary;
    }

    &-body {
      padding: 0;
    }

    &-footer {
      background-color: $background-color-primary;
      color: $font-color-secondary;
    }
  }

  &.approve {
    .card-header {
      background-color: $background-color-secondary;
      color: $background-color-primary;
    }
  }

  &.approve, &.edit {
    .card-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
    }
  }

  button {
    margin-left: $default-margin;
    margin-right: $default-margin;
    background-color: transparent;
    font-weight: bold;
    border: $default-border-width solid $background-color-secondary;
    border-radius: $default-border-radius;
    color: $font-color-primary;

    &:hover {
      background-color: $background-color-secondary;
      border: $default-border-width solid $background-color-secondary;
      color: $font-color-secondary;
    }
  }

  .dropdown {
    button {
      background-color: $background-color-secondary;
      color: $font-color-secondary;
    }

    .dropdown-menu {
      :first-child {
        border-radius: $default-border-radius $default-border-radius 0 0;
      }

      :last-child {
        border-bottom: $default-border-width solid $background-color-secondary;
        border-radius: 0 0 $default-border-radius $default-border-radius;
      }
    }

    .dropdown-item {
      margin: 0;
      padding: 0.5rem;
      background-color: $font-color-secondary;
      border-radius: 0;
      color: $background-color-secondary;

      &:hover {
        background-color: $background-color-secondary;
        color: $font-color-secondary;
      }

      &:not(last-child) {
        border-bottom: 0;
      }
    }
  }
}
